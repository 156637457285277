import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class MotivationsService {
  getMotivationsList () {
    return axios
       .get(`${API_URL}admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/motivational-cards`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getMotivationCard (id: string) {
    return axios
       .get(`${API_URL}admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/motivational-cards/${id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  getMotivationCardsCampaigns () {
    return axios
       .get(`${API_URL}admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/motivational-cards/campaigns`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
  deleteMotivationCard (id: string) {
    return axios
       .delete(`${API_URL}admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/motivational-cards/${id}`, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }
}

export default new MotivationsService()
