
import { defineComponent } from "vue";
import { getLocalStorageItem } from "@/utils/localStorage";
import Logo from "@/assets/svg/logo.svg?inline";
import Copy from "@/assets/svg/copy.svg?inline";
import MobileAlert from "@/assets/svg/mobile-alert.svg?inline";

export default defineComponent({
  name: "Header",
  data() {
    return {
      mobileMenu: false,
      email: "",
      school: [],
      role: "admin",
      copied: false,
      schoolCode: null,
      isDefaultRoute: true,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isSuperAdminPage() {
      return this.$route.path.includes("super-admin");
    },
    showSchoolBlock() {
      return this.$route.path !== "/" && this.$route.path !== "/school-choice";
    },
  },
  mounted() {
    this.email = getLocalStorageItem<string>("admin-sra-name") || "";
    this.school = getLocalStorageItem<any[]>("sra-school") || [];
    this.role = getLocalStorageItem<string[]>("sra-roles")?.[0] || "admin";
    if (this.$route.path == "/school-choice") {
      this.isDefaultRoute = false;
    }
  },
  methods: {
    async logout() {
      try {
        await this.$store.dispatch("auth/logout");
        localStorage.removeItem("sra-school");
        localStorage.removeItem("is-subscription-enabled");
        localStorage.removeItem("admin-school-id");
        this.$router.push("/");
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    async stopImpersonate() {
      const token = localStorage.getItem("super-admin-sra");
      if (token) {
        localStorage.setItem("admin-sra", token);
        this.$router.push("/super-admin/school-list");
      } else {
        console.warn("No token found for impersonation.");
      }
    },
    async copyText() {
      const textToCopy = this.school?.code;
      try {
        await navigator.clipboard.writeText(textToCopy);
        this.copied = true;
        setTimeout(() => (this.copied = false), 3000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
  },
  components: {
    Logo,
    Copy,
    MobileAlert,
  },
});
