import MotivationsService from '@/services/motivations.service'
import router from '@/router'

const admin: [] = JSON.parse(localStorage.getItem('admin-sra') || '{}')
const initialState = admin
  ? { status: { loggedIn: true }, admin }
  : { status: { loggedIn: false }, admin: null }

export const motivations = {
  namespaced: true,
  state: initialState,
  actions: {
    getMotivationsList({ commit }: any,) {
      return MotivationsService.getMotivationsList().then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getMotivationCard({ commit }: any, id: string) {
      return MotivationsService.getMotivationCard(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    getMotivationCardsCampaigns({ commit }: any) {
      return MotivationsService.getMotivationCardsCampaigns().then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    },
    deleteMotivationCard({ commit }: any, id: string) {
      return MotivationsService.deleteMotivationCard(id).then(
        admin => {
          return Promise.resolve(admin)
        },
        error => {
          console.log(error.response.status === 401, 'error.response.status')
          if (error.response.status === 401) {
            localStorage.removeItem('admin-sra')
            localStorage.removeItem('admin-sra-name')
            router.push('/')
            return
          }
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
